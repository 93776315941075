<template>
  <div class="relative py-0 pt-4 lg:py-4.5">
    <UiGradientBackground severity="secondary" class="absolute left-0 top-0" />
    <UiContainer tag="section" class="relative">
      <UiTitle severity="h6" class="mb-2 text-sm lg:mb-1 lg:font-bold">
        Организатор мероприятия
      </UiTitle>
      <ul
        class="flex flex-col gap-y-1 text-xxs text-text-tertiary lg:grid lg:grid-cols-5 lg:gap-6 lg:gap-y-2 lg:text-sm lg:text-text-secondary"
      >
        <li class="flex flex-col gap-y-1 lg:col-span-2 lg:gap-y-2">
          <p v-if="value.name" class="text-text-secondary">
            <span class="lg:hidden">Организатор мероприятия:</span> {{ value.name }}
          </p>
          <p v-if="value.address && !isSoleProprietorship">
            Юридический адрес: {{ value.address }}
          </p>
        </li>
        <li class="flex flex-col gap-y-1 lg:gap-y-2">
          <p v-if="value.inn">ИНН: {{ value.inn }}</p>
          <p v-if="value.ogrn">ОГРН: {{ value.ogrn }}</p>
        </li>
      </ul>
    </UiContainer>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { UiContainer, UiGradientBackground, UiTitle } from '@/6_shared/ui'
import type { Organizer } from '../../model'

type PropType = {
  value: Organizer
}

const props = defineProps<PropType>()

const isSoleProprietorship = computed(() => props.value.inn.length === 12)
</script>
